export const BULK_LIST_REQUEST = "BULK_LIST_REQUEST";
export const BULK_LIST_SUCCESS = "BULK_LIST_SUCCESS";
export const BULK_LIST_FAIL = "BULK_LIST_FAIL";

export const BULK_LIST_BY_PRODUCT_ID_REQUEST =
  "BULK_LIST_BY_PRODUCT_ID_REQUEST";
export const BULK_LIST_BY_PRODUCT_ID_SUCCESS =
  "BULK_LIST_BY_PRODUCT_ID_SUCCESS";
export const BULK_LIST_BY_PRODUCT_ID_FAIL = "BULK_LIST_BY_PRODUCT_ID_FAIL";

export const BULK_DELETE_BY_PRODUCT_ID_REQUEST =
  "BULK_DELETE_BY_PRODUCT_ID_REQUEST";
export const BULK_DELETE_BY_PRODUCT_ID_SUCCESS =
  "BULK_DELETE_BY_PRODUCT_ID_SUCCESS";
export const BULK_DELETE_BY_PRODUCT_ID_FAIL = "BULK_DELETE_BY_PRODUCT_ID_FAIL";

export const BULK_CREATE_BY_PRODUCT_ID_REQUEST =
  "BULK_CREATE_BY_PRODUCT_ID_REQUEST";
export const BULK_CREATE_BY_PRODUCT_ID_SUCCESS =
  "BULK_CREATE_BY_PRODUCT_ID_SUCCESS";
export const BULK_CREATE_BY_PRODUCT_ID_FAIL = "BULK_CREATE_BY_PRODUCT_ID_FAIL";

export const BULK_UPDATE_BY_PRODUCT_ID_REQUEST =
  "BULK_UPDATE_BY_PRODUCT_ID_REQUEST";
export const BULK_UPDATE_BY_PRODUCT_ID_SUCCESS =
  "BULK_UPDATE_BY_PRODUCT_ID_SUCCESS";
export const BULK_UPDATE_BY_PRODUCT_ID_FAIL = "BULK_UPDATE_BY_PRODUCT_ID_FAIL";
