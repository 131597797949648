import {
  STOCK_COUNT_BY_PRODUCT_ID_FAILURE,
  STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
  STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE,
} from "../constants/stockConstants";

export const stockCountByProductIdReducer = (
  state = { stockCount: {} },
  action
) => {
  switch (action.type) {
    case STOCK_COUNT_BY_PRODUCT_ID_REQUEST:
      return { loading: true, stockCount: {} };
    case STOCK_COUNT_BY_PRODUCT_ID_SUCCESS:
      return {
        loading: false,
        stockCount: action.payload,
      };
    case STOCK_COUNT_BY_PRODUCT_ID_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const updateStockCountByProductIdReducer = (
  state = { stockCount: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST:
      return { loading: true };
    case UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
