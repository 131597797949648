export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_EDIT_ITEM = "CART_EDIT_ITEM";
export const CART_CLEAR_ITEMS = "CART_RESET";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_DETAILS = "CART_SAVE_PAYMENT_DETAILS";
export const CART_ITEMS_COUNT = "CART_ITEMS_COUNT";

export const CART_ADD_ITEM_SUCCESS = "CART_ADD_ITEM_SUCCESS";
export const CART_ADD_ITEM_RESET = "CART_ADD_ITEM_RESET";
export const CART_ADD_ITEM_FAIL = "CART_ADD_ITEM_FAIL";

export const CART_INCREMENT_ITEM_QUANTITY = "CART_INCREMENT_ITEM_QUANTITY";
export const CART_DECREMENT_ITEM_QUANTITY = "CART_DECREMENT_ITEM_QUANTITY";


