import {
  CALCULATE_NEAREST_STORE_REQUEST,
  CALCULATE_NEAREST_STORE_SUCCESS,
  CALCULATE_NEAREST_STORE_FAIL,
  CALCULATE_NEAREST_STORE_RESET,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL,
  CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET,
} from "../constants/geoConstants";

export const nearestStoreReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_NEAREST_STORE_REQUEST:
      return { loading_nearest_store_result: true };
    case CALCULATE_NEAREST_STORE_SUCCESS:
      return { loading_nearest_store_result: false, storeInfo: action.payload };
    case CALCULATE_NEAREST_STORE_FAIL:
      return { loading_nearest_store_result: false, error: action.payload };
    case CALCULATE_NEAREST_STORE_RESET:
      return { loading_nearest_store_result: false, storeInfo: {} };
    default:
      return state;
  }
};

export const nearestStoreByZipcodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_REQUEST:
      return { loading_nearest_store_result_by_zipcode: true };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_SUCCESS:
      return {
        loading_nearest_store_result_by_zipcode: false,
        storeInfoByZipcode: action.payload,
      };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_FAIL:
      return {
        loading_nearest_store_result_by_zipcode: false,
        error: action.payload,
      };
    case CALCULATE_NEAREST_STORE_BY_ZIPCODE_RESET:
      return {
        loading_nearest_store_result_by_zipcode: false,
        storeInfoByZipcode: {},
      };
    default:
      return state;
  }
};
