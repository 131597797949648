import {
  WHATS_APP_INVOICE_BY_ID_REQUEST,
  WHATS_APP_INVOICE_BY_ID_SUCCESS,
  WHATS_APP_INVOICE_BY_ID_FAIL,
  WHATS_APP_INVOICE_BY_ID_RESET,
  WHATS_APP_INVOICE_CREATE_REQUEST,
  WHATS_APP_INVOICE_CREATE_FAIL,
  WHATS_APP_INVOICE_CREATE_RESET,
  WHATS_APP_INVOICE_CREATE_SUCCESS,
  WHATS_APP_INVOICE_DETAILS_REQUEST,
  WHATS_APP_INVOICE_DETAILS_SUCCESS,
  WHATS_APP_INVOICE_DETAILS_FAIL,
  WHATS_APP_INVOICE_DETAILS_RESET,
  WHATS_APP_INVOICE_LIST_REQUEST,
  WHATS_APP_INVOICE_LIST_SUCCESS,
  WHATS_APP_INVOICE_LIST_FAIL,
  WHATS_APP_INVOICE_LIST_RESET

} from "../constants/whatsAppInvoiceConstants";

export const whatsAppInvoiceListReducer = (state = { whats_app_invoices: [] }, action) => {
  switch (action.type) {
    case WHATS_APP_INVOICE_LIST_REQUEST:
      return { loading: true , whats_app_invoices: []};
    case WHATS_APP_INVOICE_LIST_SUCCESS:
      return { loading: false, whats_app_invoices: action.payload };
    case WHATS_APP_INVOICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case WHATS_APP_INVOICE_LIST_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const whatsAppInvoiceByIdReducer = (
  state = { whats_app_invoice: {} },
  action
) => {
  switch (action.type) {
    case WHATS_APP_INVOICE_BY_ID_REQUEST:
      return { loading: true, whats_app_invoice: {} };
    case WHATS_APP_INVOICE_BY_ID_SUCCESS:
      return { loading: false, whats_app_invoice: action.payload };
    case WHATS_APP_INVOICE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const whatsAppInvoiceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case WHATS_APP_INVOICE_DETAILS_REQUEST:
      return { loading: true };
    case WHATS_APP_INVOICE_DETAILS_SUCCESS:
      return { loading: false, whats_app_invoice: action.payload };
    case WHATS_APP_INVOICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case WHATS_APP_INVOICE_DETAILS_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const whatsAppInvoiceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WHATS_APP_INVOICE_CREATE_REQUEST:
      return { loading: true };
    case WHATS_APP_INVOICE_CREATE_SUCCESS:
      return { loading: false, success_create: true, whats_app_invoice: action.payload };
    case WHATS_APP_INVOICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case WHATS_APP_INVOICE_CREATE_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};


