export const PDF_INVOICE_BY_ID_REQUEST = "PDF_INVOICE_BY_ID_REQUEST";
export const PDF_INVOICE_BY_ID_SUCCESS = "PDF_INVOICE_BY_ID_SUCCESS";
export const PDF_INVOICE_BY_ID_FAIL = "PDF_INVOICE_BY_ID_FAIL";
export const PDF_INVOICE_BY_ID_RESET = "PDF_INVOICE_BY_ID_RESET";

export const PDF_INVOICE_CREATE_REQUEST = "PDF_INVOICE_CREATE_REQUEST";
export const PDF_INVOICE_CREATE_SUCCESS = "PDF_INVOICE_CREATE_SUCCESS";
export const PDF_INVOICE_CREATE_FAIL = "PDF_INVOICE_CREATE_FAIL";
export const PDF_INVOICE_CREATE_RESET = "PDF_INVOICE_CREATE_RESET";

export const PDF_INVOICE_DETAILS_REQUEST = "PDF_INVOICE_DETAILS_REQUEST";
export const PDF_INVOICE_DETAILS_SUCCESS = "PDF_INVOICE_DETAILS_SUCCESS";
export const PDF_INVOICE_DETAILS_FAIL = "PDF_INVOICE_DETAILS_FAIL";
export const PDF_INVOICE_DETAILS_RESET = "PDF_INVOICE_DETAILS_RESET";

export const PDF_INVOICE_LIST_REQUEST = "PDF_INVOICE_LIST_REQUEST";
export const PDF_INVOICE_LIST_SUCCESS = "PDF_INVOICE_LIST_SUCCESS";
export const PDF_INVOICE_LIST_FAIL = "PDF_INVOICE_LIST_FAIL";
export const PDF_INVOICE_LIST_RESET = "PDF_INVOICE_LIST_RESET";
