export const SUB_CATEGORY_LIST_REQUEST = "SUB_CATEGORY_LIST_REQUEST";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAIL = "SUB_CATEGORY_LIST_FAIL";

export const SUB_CATEGORY_LIST_BY_CATEGORY_ID_REQUEST =
  "SUB_CATEGORY_LIST_BY_CATEGORY_ID_REQUEST";
export const SUB_CATEGORY_LIST_BY_CATEGORY_ID_SUCCESS =
  "SUB_CATEGORY_LIST_BY_CATEGORY_ID_SUCCESS";
export const SUB_CATEGORY_LIST_BY_CATEGORY_ID_FAIL =
  "SUB_CATEGORY_LIST_BY_CATEGORY_ID_FAIL";

export const SUB_CATEGORY_CREATE_BY_CATEGORY_ID_REQUEST =
  "SUB_CATEGORY_CREATE_BY_CATEGORY_ID_REQUEST";
export const SUB_CATEGORY_CREATE_BY_CATEGORY_ID_SUCCESS =
  "SUB_CATEGORY_CREATE_BY_CATEGORY_ID_SUCCESS";
export const SUB_CATEGORY_CREATE_BY_CATEGORY_ID_FAIL =
  "SUB_CATEGORY_CREATE_BY_CATEGORY_ID_FAIL";

export const SUB_CATEGORY_DELETE_REQUEST = "SUB_CATEGORY_DELETE_REQUEST";
export const SUB_CATEGORY_DELETE_SUCCESS = "SUB_CATEGORY_DELETE_SUCCESS";
export const SUB_CATEGORY_DELETE_FAIL = "SUB_CATEGORY_DELETE_FAIL";

export const SUB_CATEGORY_CREATE_REQUEST = "SUB_CATEGORY_CREATE_REQUEST";
export const SUB_CATEGORY_CREATE_SUCCESS = "SUB_CATEGORY_CREATE_SUCCESS";
export const SUB_CATEGORY_CREATE_FAIL = "SUB_CATEGORY_CREATE_FAIL";
export const SUB_CATEGORY_CREATE_RESET = "SUB_CATEGORY_CREATE_RESET";

export const SUB_CATEGORY_UPDATE_REQUEST = "SUB_CATEGORY_UPDATE_REQUEST";
export const SUB_CATEGORY_UPDATE_SUCCESS = "SUB_CATEGORY_UPDATE_SUCCESS";
export const SUB_CATEGORY_UPDATE_FAIL = "SUB_CATEGORY_UPDATE_FAIL";
export const SUB_CATEGORY_UPDATE_RESET = "SUB_CATEGORY_UPDATE_RESET";
