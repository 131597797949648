import {
  PDF_INVOICE_BY_ID_REQUEST,
  PDF_INVOICE_BY_ID_SUCCESS,
  PDF_INVOICE_BY_ID_FAIL,
  PDF_INVOICE_BY_ID_RESET,
  PDF_INVOICE_CREATE_REQUEST,
  PDF_INVOICE_CREATE_FAIL,
  PDF_INVOICE_CREATE_RESET,
  PDF_INVOICE_CREATE_SUCCESS,
  PDF_INVOICE_DETAILS_REQUEST,
  PDF_INVOICE_DETAILS_SUCCESS,
  PDF_INVOICE_DETAILS_FAIL,
  PDF_INVOICE_DETAILS_RESET,
  PDF_INVOICE_LIST_REQUEST,
  PDF_INVOICE_LIST_SUCCESS,
  PDF_INVOICE_LIST_FAIL,
  PDF_INVOICE_LIST_RESET

} from "../constants/pdfInvoiceConstants";

export const pdfInvoiceListReducer = (state = { pdf_invoices: [] }, action) => {
  switch (action.type) {
    case PDF_INVOICE_LIST_REQUEST:
      return { loading: true , pdf_invoices: []};
    case PDF_INVOICE_LIST_SUCCESS:
      return { loading: false, pdf_invoices: action.payload };
    case PDF_INVOICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case PDF_INVOICE_LIST_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const pdfInvoiceByIdReducer = (
  state = { pdf_invoice: {} },
  action
) => {
  switch (action.type) {
    case PDF_INVOICE_BY_ID_REQUEST:
      return { loading: true, pdf_invoice: {} };
    case PDF_INVOICE_BY_ID_SUCCESS:
      return { loading: false, pdf_invoice: action.payload };
    case PDF_INVOICE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const pdfInvoiceDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case PDF_INVOICE_DETAILS_REQUEST:
      return { loading: true };
    case PDF_INVOICE_DETAILS_SUCCESS:
      return { loading: false, pdf_invoice: action.payload };
    case PDF_INVOICE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case PDF_INVOICE_DETAILS_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const pdfInvoiceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PDF_INVOICE_CREATE_REQUEST:
      return { loading: true };
    case PDF_INVOICE_CREATE_SUCCESS:
      return { loading: false, success_create: true, pdf_invoice: action.payload };
    case PDF_INVOICE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PDF_INVOICE_CREATE_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};


