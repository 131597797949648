import {
  CART_ADD_ITEM,
  CART_ADD_ITEM_RESET,
  CART_ADD_ITEM_SUCCESS,
  CART_CLEAR_ITEMS,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_DETAILS,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_INCREMENT_ITEM_QUANTITY,
  CART_DECREMENT_ITEM_QUANTITY,
} from "../constants/cartConstants";
export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      //console.log("EXEC CART_ADD_ITEM" , item);
      // state.cartItems.map(e=>//console.log("Each Cart Item :: from Reducer ::===>> ",e));
      const existsItem = state.cartItems.find(
        (x) => x.name === item.name
      );
      if (existsItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.name === existsItem.name ? item : x
          ),
          cartModified: true,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
          cartModified: true,
        };
      };
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.name !== action.payload),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case CART_SAVE_PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    case CART_ADD_ITEM_SUCCESS:
      return { ...state, add_items_to_cart_create_success: true, cartItems: [...state.cartItems] };
    case CART_ADD_ITEM_RESET:
      return { ...state, add_items_to_cart_create_success: false, cartItems: [...state.cartItems] };
    case CART_INCREMENT_ITEM_QUANTITY:
      const itm = action.payload;
      console.log("EXEC CART_INCREMENT_ITEM_QUANTITY");
      console.log("action  : ", action.type, "payload : ", action.payload);
      console.log("itm : ", itm.item);
      console.log("ITEMS IN CART OF STORE STATE :==>  ", state.cartItems);
      const matchedItem = state.cartItems.find(
        (x) => x.name === itm.item.name
      );
      console.log("RESULT OF MATCHED ITEM : ", matchedItem);
      if (matchedItem) {
        console.log("Items Exists in CART SO MATCHED : ", matchedItem);
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.name === matchedItem.name ? { ...x, quantity: Number(x.quantity) + 1 } : x
          ),
        };
      }
    case CART_DECREMENT_ITEM_QUANTITY:
      console.log("EXEC CART_DECREMENT_ITEM_QUANTITY action.type :  ", action.type, "action.payload : ", action.payload, "Name : ", action.payload.item.name);
      console.log("ITEMS IN CART OF STORE STATE :==>  ", state.cartItems);
      const fountItem = state.cartItems.find(
        (x) => x.name === action.payload.item.name
      );
      console.log("RESULT OF FIND ITEM : ", fountItem);
      if (fountItem) {
        console.log("Items Exists in CART SO MATCHED : ", fountItem);
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.name === fountItem.name ? { ...x, quantity: Number(x.quantity) > 1 ? Number(x.quantity) - 1 : 1 } : x
          ),
        };
      }

    default:
      return state;
  }
};
