export const STORE_STOCK_LIST_BY_STORE_NAME_REQUEST = "STORE_STOCK_LIST_BY_STORE_NAME_REQUEST";
export const STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS = "STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS";
export const STORE_STOCK_LIST_BY_STORE_NAME_FAIL = "STORE_STOCK_LIST_BY_STORE_NAME_FAIL";
export const STORE_STOCK_LIST_BY_STORE_NAME_RESET = "STORE_STOCK_LIST_BY_STORE_NAME_RESET";

export const STORE_STOCK_BY_STORE_NAME_REQUEST =
  "STORE_STOCK_BY_STORE_NAME_REQUEST";
export const STORE_STOCK_BY_STORE_NAME_SUCCESS =
  "STORE_STOCK_BY_STORE_NAME_SUCCESS";
export const STORE_STOCK_BY_STORE_NAME_FAIL =
  "STORE_STOCK_BY_STORE_NAME_FAIL";

export const STORE_STOCK_DELETE_BY_ID_REQUEST = "STORE_STOCK_DELETE_BY_ID_REQUEST";
export const STORE_STOCK_DELETE_BY_ID_SUCCESS = "STORE_STOCK_DELETE_BY_ID_SUCCESS";
export const STORE_STOCK_DELETE_BY_ID_FAIL = "STORE_STOCK_DELETE_BY_ID_FAIL";

export const STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST = "STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST";
export const STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS = "STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS";
export const STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL = "STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL";
export const STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET = "STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET";

export const STORE_STOCK_OUT_CREATE_REQUEST = "STORE_STOCK_OUT_CREATE_REQUEST";
export const STORE_STOCK_OUT_CREATE_SUCCESS = "STORE_STOCK_OUT_CREATE_SUCCESS";
export const STORE_STOCK_OUT_CREATE_FAIL = "STORE_STOCK_OUT_CREATE_FAIL";
export const STORE_STOCK_OUT_CREATE_RESET = "STORE_STOCK_OUT_CREATE_RESET";

export const STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST = "STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST";
export const STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS = "STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS";
export const STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL = "STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL";
export const STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET = "STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET";

export const STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_REQUEST = "STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_REQUEST";
export const STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_SUCCESS = "STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_SUCCESS";
export const STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_FAIL = "STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_FAIL";
export const STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_RESET = "STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_RESET";



