import React, { Fragment, useState } from 'react';
import { auth , RecaptchaVerifier, signInWithPhoneNumber } from '../config/firebaseConfig';
import {
  Button,
  TextField,
} from "@mui/material";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import { Divider } from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


const OtpAuthentication = () => {
  const [phoneNumber, setPhoneNumber] = useState(() => '');
  const [user, setUser] = useState(() => null);
  const [otp, setOtp] = useState(() => '');
  const [verificationId, setVerificationId] = useState('');
  const [message, setMessage] = useState('');

  const sendOtp = async () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
      // const recaptchaVerifier = new RecaptchaVerifier(auth,"recaptcha",{})
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      console.log('Response of confirmation : ', confirmation)
      if (confirmation) {
        setUser(() => confirmation)
        window.confirmationResult = confirmation;
        setVerificationId(confirmation.verificationId);
        setMessage('OTP has been sent to your phone number');
      }

    } catch (error) {
      console.log(error);
      setMessage('Failed to send OTP. Try again.', error);
    }
  }

  const verifyOtp = async () => {
    try {
      console.log('Exec verifyOtp ')
      // const data = await user.confirm(otp)
      window.confirmationResult.confirm(otp).then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log('User signed in successfully.: ', user)
      });
      console.log('Response of user : ', user)
    } catch (error) {
      console.log(error);
      setMessage('Failed to Validate the OTP.', error);
    }
  }
  return (
    <GridContainer spacing={2}>
      <GridItem xs={12} style={{ alignSelf: "center", justifyContent: "center", display: "flex" }}>
        <Card style={{ width: '50rem', height: '50rem', alignItems: "center", justifyContent: "center", textAlign: "center", display: "felx" }}>
          <CardHeader>
            <h2 align="center"> Tagline Traders OTP Authentication </h2>
            <Divider />
          </CardHeader>
          <CardBody >

            {/* <PhoneInput
              style={{
                justifyContent: "center",
                width:'20rem'
              }}
              placeholder="Contact #"
              defaultCountry="in"
              name="phoneNumber"
              size="small"
              required
              onChange={(phoneNo) => setPhoneNumber(() => phoneNo)}
              value={phoneNumber ? phoneNumber : ""}
            /> */}
            <TextField
              style={{
                justifyContent: "center",
                width:'20rem'
              }}
              name="phoneNumber"
              size="small"
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber ? phoneNumber : ""}
            />
            {/* <input
                type="tel"
                placeholder="Phone number"
                value={phoneNumber}
                style={{ width: '75%', height: '2.5rem', borderRadius: '5px' }}
                onChange={(e) => setPhoneNumber(()=>e.target.value)}
                required
              /> */}
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                width: "20vh",
                borderRadius: "5px",
                marginTop: "1rem"
              }}
              onClick={sendOtp}
            >
              Send OTP
            </Button>
            <TextField
              placeholder="Enter OTP"
              label="Enter OTP"
              required
              autoFocus
              variant="outlined"
              size="small"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              style={{ width: "20rem" }}
            />
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                width: "20vh",
                borderRadius: "5px",
                marginTop: "1rem"
              }}
              onClick={verifyOtp}
            >Verify OTP</Button>
            <div id="recaptcha-container"></div>
            <p>{message}</p>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OtpAuthentication;
