import { initializeApp } from "firebase/app";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// Tagline Traders web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCWGQuhGtgzEyRZgNzHMRx-cT_xYUHE9II",
  authDomain: "taglinetraders-2f03b.firebaseapp.com",
  projectId: "taglinetraders-2f03b",
  storageBucket: "taglinetraders-2f03b.appspot.com",
  messagingSenderId: "384737113236",
  appId: "1:384737113236:web:a9ab7009b13a9371163081"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// auth.useDeviceLanguage();
// auth.languageCode = 'in';

export { auth, RecaptchaVerifier, signInWithPhoneNumber };
