import {
  STORE_STOCK_LIST_BY_STORE_NAME_REQUEST,
  STORE_STOCK_LIST_BY_STORE_NAME_FAIL,
  STORE_STOCK_LIST_BY_STORE_NAME_RESET,
  STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_REQUEST,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_FAIL,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_SUCCESS,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS
} from "../constants/storeStockConstants";

export const storeStockListByStoreNameReducer = (
  state = { store_stock_data: {} },
  action
) => {
  switch (action.type) {
    case STORE_STOCK_LIST_BY_STORE_NAME_REQUEST:
      return { store_stock_loading: true, store_stock_data:{}  };
    case STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS:
      return {
        store_stock_loading: false,
        store_stock_data: action.payload,
      };
    case STORE_STOCK_LIST_BY_STORE_NAME_FAIL:
      return { store_stock_loading: false, store_stock_error: action.payload };
    default:
      return state;
  }
};

export const storeStockCreateByStoreNameReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST:
      return { store_stock_create_loading: true };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS:
      return { store_stock_create_loading: false, store_stock_create_success: true, store_stock_create_data: action.payload };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL:
      return { store_stock_create_loading: false, store_stock_create_error: action.payload };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET:
      return {};
    default:
      return state;
  }
};

export const storeStockUpdateByStoreNameReducer  = (state = { store_stock: {} }, action) => {
  switch (action.type) {
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST:
      return { store_stock_update_loading: true };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS:
      return { store_stock_update_loading: false, store_stock_update_success: true ,store_stock_update_data: action.payload };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL:
      return { lstore_stock_update_loading: false, store_stock_update_error: action.payload };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET:
      return { store_stock: {} };
    default:
      return state;
  }
};
