import { createTheme } from "@mui/material/styles";

const nlGreen = "#26A541";
const nlMeroon = "#800000";
const nlGray = "#868686";
const nlBlack = "#000000";
const fkOrange = "#f0c14b";

export default createTheme({
  palette: {
    common: {
      blue: nlGreen,
      orange: nlMeroon,
    },
    primary: {
      main: nlGreen,
    },
    secondary: {
      main: fkOrange,
    },
  },
  layout: {
    contentWidth: 1236,
  },
  typography: {
    tab: {
      fontFamily: "Roboto ,Helvetica,Arial",
      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem",
    },
    estimate: {
      fontFamily: "Roboto ,Helvetica,Arial",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
    h2: {
      fontFamily: "Roboto ,Helvetica,Arial",
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "2.5rem",
      color: nlGreen,
    },
    h3: {
      fontFamily: "Roboto ,Helvetica,Arial",
      fontSize: "2.0rem",
      color: nlGreen,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h4: {
      fontFamily: "Roboto ,Helvetica,Arial",
      fontSize: "1.75rem",
      color: nlGreen,
      fontWeight: 700,
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Roboto ,Helvetica,Arial",
      color: nlGreen,
    },
    subtitle1: {
      fontSize: "0.80rem",
      fontWeight: 500,
      color: nlGray,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    subtitle3: {
      fontSize: "0.90rem",
      fontWeight: 600,
      color: nlGray,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    productTitle: {
      fontSize: "0.80rem",
      fontWeight: 300,
      color: nlGray,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    subtitle2: {
      fontSize: "0.85rem",
      fontWeight: 600,
      color: nlBlack,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    body1: {
      fontSize: "0.85rem",
      color: nlBlack,
      fontWeight: 500,
      fontFamily: "Roboto ,Helvetica,Arial",
      textTransform: "capitalize",
      textAlign: "left",
    },
    body2: {
      fontSize: "0.79rem",
      color: nlBlack,
      fontWeight: 250,
      fontFamily: "Roboto,Helvetica,Arial",
      textTransform: "capitalize",
      textAlign: "left",
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: nlGray,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    learnButton: {
      borderColor: nlBlack,
      borderWidth: 2,
      textTransform: "none",
      color: nlGreen,
      borderRadius: 50,
      fontFamily: "Roboto ,Helvetica,Arial",
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: "gray",
      },
      colorSecondary: {
        "&$checked": {
          color: "darkgray",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: nlBlack,
        fontSize: "1rem",
        fontFamily: "Roboto ,Helvetica,Arial",
      },
    },
    MuiOutlinedInput: {
      root: {
        width: "auto",
      },
    },
    MuiMenuItem: {
      root: {
        color: nlGray,
        "&$selected": {
          // <-- mixing the two classes
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: nlGray,
          fontSize: "0.75rem",
          fontWeight: 400,
          fontFamily: "Roboto ,Helvetica,Arial",
        },
        color: "gray",
        fontSize: "0.85rem",
        fontWeight: 500,
        fontFamily: "Roboto ,Helvetica,Arial", // if you also want to change the color of the input, this is the prop you'd use
      },
      root: {
        color: nlGray,
        fontWeight: 300,
        fontFamily: "Roboto ,Helvetica,Arial",
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${nlGreen}`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${nlGreen}`,
        },
      },
    },
    MuiStepLabel: {
      root: {
        color: nlGreen,
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        textAlign: "center"
      },
      label: {
        color: nlGreen,
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        textAlign: "center"
      },
      alternativeLabel: {
        color: nlGreen,
        fontWeight: 400,
        fontFamily: '"Roboto", sans-serif',
        textAlign: "center"
      },
    }
  },
});
