export const STOCK_COUNT_BY_PRODUCT_ID_REQUEST =
  "STOCK_COUNT_BY_PRODUCT_ID_REQUEST";
export const STOCK_COUNT_BY_PRODUCT_ID_SUCCESS =
  "STOCK_COUNT_BY_PRODUCT_ID_SUCCESS";
export const STOCK_COUNT_BY_PRODUCT_ID_FAILURE =
  "STOCK_COUNT_BY_PRODUCT_ID_FAILURE";

export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST";
export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS";
export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE";
