export const WHATS_APP_INVOICE_BY_ID_REQUEST = "WHATS_APP_INVOICE_BY_ID_REQUEST";
export const WHATS_APP_INVOICE_BY_ID_SUCCESS = "WHATS_APP_INVOICE_BY_ID_SUCCESS";
export const WHATS_APP_INVOICE_BY_ID_FAIL = "WHATS_APP_INVOICE_BY_ID_FAIL";
export const WHATS_APP_INVOICE_BY_ID_RESET = "WHATS_APP_INVOICE_BY_ID_RESET";

export const WHATS_APP_INVOICE_CREATE_REQUEST = "WHATS_APP_INVOICE_CREATE_REQUEST";
export const WHATS_APP_INVOICE_CREATE_SUCCESS = "WHATS_APP_INVOICE_CREATE_SUCCESS";
export const WHATS_APP_INVOICE_CREATE_FAIL = "WHATS_APP_INVOICE_CREATE_FAIL";
export const WHATS_APP_INVOICE_CREATE_RESET = "WHATS_APP_INVOICE_CREATE_RESET";

export const WHATS_APP_INVOICE_DETAILS_REQUEST = "WHATS_APP_INVOICE_DETAILS_REQUEST";
export const WHATS_APP_INVOICE_DETAILS_SUCCESS = "WHATS_APP_INVOICE_DETAILS_SUCCESS";
export const WHATS_APP_INVOICE_DETAILS_FAIL = "WHATS_APP_INVOICE_DETAILS_FAIL";
export const WHATS_APP_INVOICE_DETAILS_RESET = "WHATS_APP_INVOICE_DETAILS_RESET";

export const WHATS_APP_INVOICE_LIST_REQUEST = "WHATS_APP_INVOICE_LIST_REQUEST";
export const WHATS_APP_INVOICE_LIST_SUCCESS = "WHATS_APP_INVOICE_LIST_SUCCESS";
export const WHATS_APP_INVOICE_LIST_FAIL = "WHATS_APP_INVOICE_LIST_FAIL";
export const WHATS_APP_INVOICE_LIST_RESET = "WHATS_APP_INVOICE_LIST_RESET";
